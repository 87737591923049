import React, { useEffect, useState, useRef } from "react";
import * as d3 from "d3";
import "d3-scale-chromatic";
import sfyApi from "../api/sfyApi.js";
import { getCookie } from "../utils/cookies.js";
import './StreamGraphGenre.css';
import { formatGenre } from "../utils/stringUtils.js";

const StreamGraphGenre = () => {
    const [sfyData, setSfyData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const svgRef = useRef(null); // Ref for the SVG container

    useEffect(() => {
        // Fetch data from the backend API
        const fetchData = async () => {
            const cookieString = getCookie("sessionUser");
            const user = JSON.parse(cookieString);
            try {
                setLoading(true);
                const data = await sfyApi.getGenreStream(user.email); // Fetch genre data from API
                setSfyData(data); // Set the fetched data in state
                setError(null);
            } catch (err) {
                console.error("Error fetching genre stream data:", err);
                setError("Failed to load data.");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {

        var selectedArea = null;
        var cachedTarget = null;
        const isMobile = window.innerWidth < 768; // Assume mobile if width is less than 768px
        const divWidth = document.getElementById("chart-container").offsetWidth;
        const divHeight = document.getElementById("chart-container").offsetHeight - 40



        if (!loading && sfyData.length > 0 && !error) {
            // Set the dimensions and margins of the graph
            // const isMobile = window.innerWidth < 768;
            const margin = { top: 20, right: 30, bottom: 50, left: 50 },
                width = divWidth - margin.left - margin.right,
                height = Math.min(divHeight, 550) - margin.top - margin.bottom,
                chartHeight = height - 260,
                graphOffset = isMobile ? 270 : 150;

            // Remove existing svg (to avoid duplicate charts on rerenders)
            d3.select(svgRef.current).select("svg").remove();

            // Append the svg object to the div
            const svg = d3
                .select(svgRef.current)
                .append("svg")
                .attr("viewBox", `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom + 50}`)
                .attr("preserveAspectRatio", "xMidYMid meet")
                .append("g")
                .attr("transform", `translate(${margin.left},${margin.top})`);

            // Create a separate group for the x-axis to lock it in place
            const xAxisGroup = svg
                .append("g")
                .attr("transform", `translate(0,${chartHeight + 260})`)
                .attr("class", "x-axis");

            // List of genres (keys), excluding the 'date' and 'hour' fields
            const keys = Object.keys(sfyData[0]).filter(key => key !== 'date' && key !== 'hour');

            // Parse the date and time (assuming the data includes 'date' and 'hour' fields)
            sfyData.forEach(function (d) {


                let formattedDate = d3.timeParse("%m/%d/%Y")(d.date); // Parse the date
                let dateStr = `${formattedDate.getFullYear()}-${('0' + (formattedDate.getMonth() + 1)).slice(-2)}-${('0' + formattedDate.getDate()).slice(-2)}T${('0' + d.hour).slice(-2)}:00:00Z`;
                let utcDateTime = new Date(dateStr);
                d.datetime = utcDateTime.toLocaleString();
                d.datetime = new Date(utcDateTime);

                const year = d.datetime.getFullYear();
                const month = String(d.datetime.getMonth() + 1).padStart(2, '0');  // Months are zero-indexed
                const day = String(d.datetime.getDate()).padStart(2, '0');
                const hour = String(d.datetime.getHours()).padStart(2, '0');  // Use local time

                // Format date as YYYY-MM-DD
                d.date = `${month}/${day}/${year}`;
                d.hour = hour;

                keys.forEach(function (key) {
                    d[key] = +d[key] || 0;  // Ensure numeric values or default to 0
                });
            });

            // X-axis: Time scale using date and hour
            const x = d3
                .scaleTime()
                .domain(d3.extent(sfyData, function (d) { return d.datetime }))
                .range([0, width]);


            // Y axis: Adjust the scale to move the data up, leaving space below for the x-axis
            const y = d3
                .scaleLinear()
                .domain([
                    0,
                    d3.max(sfyData, function (d) {
                        return d3.sum(keys, function (k) { return +d[k]; });
                    }),
                ])
                .range([chartHeight, graphOffset]);

            // Render the x-axis initially
            xAxisGroup.call(d3.axisBottom(x).ticks(isMobile ? 4 : 6));
            xAxisGroup.selectAll(".domain").style("stroke", "white");
            xAxisGroup.selectAll(".tick line").style("stroke", "white");
            xAxisGroup.selectAll(".tick text").style("fill", "white");

            // Color palette
            const color = d3.scaleOrdinal()
                .domain(d3.range(25))
                .range(d3.quantize(d3.interpolateRainbow, 25));

            // Stack the data per genre (normalized stacking for silhouette)
            const stackedData = d3
                .stack()
                .keys(keys)
                .offset(d3.stackOffsetSilhouette)(sfyData);

            // Create the vertical line for tooltip tracking
            const verticalLine = svg
                .append("line")
                .style("stroke", "white")
                .style("stroke-width", 1)
                .style("opacity", 0)
                .attr("y1", 0)
                .attr("y2", height + 100); // Adjust for new chartHeight



            // Tooltip div
            const tooltipDiv = d3
                .select("body")
                .append("div")
                .style("opacity", 0)
                .attr("class", "tooltip")
                .style("position", "absolute")
                .style('background-color', 'rgba(0, 0, 0, 0.7)')
                .style('color', 'white')
                .style("padding", window.innerWidth < 600 ? "8px" : "10px") // Smaller padding on mobile
                .style("border-radius", "8px")
                .style("box-shadow", "0px 4px 8px rgba(0, 0, 0, 0.1)")
                .style("pointer-events", "none");

            // Function to find the closest data point
            function getClosestDataPoint(mouseX) {
                const mouseDate = x.invert(mouseX); // Use the x scale to map the mouse's x position back to a datetime
                const closestData = sfyData.reduce(function (prev, curr) {
                    return Math.abs(curr.datetime - mouseDate) <
                        Math.abs(prev.datetime - mouseDate)
                        ? curr
                        : prev;
                });
                return closestData;
            }

            // Function to find the closest data point
            function getClosestSelectedDataPoint(mouseX) {
                const mouseDate = x.invert(mouseX); // Use the x scale to map the mouse's x position back to a datetime
                const closestData = sfyData.reduce(function (prev, curr) {
                    return Math.abs(curr.datetime - mouseDate) <
                        Math.abs(prev.datetime - mouseDate)
                        ? curr
                        : prev;
                });
                return closestData;
            }

            const clickData = function () {
                // Highlight the clicked area
                tooltipDiv.style("opacity", 1);

                // set color area selection
                d3.selectAll(".myArea").style("opacity", 0.2);
                d3.select(this)
                    .style("stroke", "black")
                    .style("stroke-width", "0.05px")
                    .style("opacity", 1);
                cachedTarget = this;


                const genre = d3.select(this).datum().key;

                selectedArea = genre; // Save the clicked data area
            }

            const formatHour = (hour) => {
                // eslint-disable-next-line
                if (hour == 0) {
                    return "12 AM";
                } else if (hour < 12) {
                    return hour + "AM";
                }
                // eslint-disable-next-line
                else if (hour == 12) {
                    return "12 PM";
                } else {
                    return hour - 12 + "PM";
                }
            }


            const mousemoveAll = function (event) {
                var mouseXRef = d3.pointer(event, cachedTarget)[0];
                var mouseX = d3.pointer(event)[0];
                verticalLine.style("opacity", 1);
                verticalLine.attr("x1", mouseX).attr("x2", mouseX);
                const closestData = getClosestSelectedDataPoint(mouseXRef); // Find the closest data point
                const hour = closestData.hour;
                const date_time = closestData.date + " " + formatHour(hour);

                //if we have a data area selected, display tooltip for that area's data corresponding to the mouse x value
                if (selectedArea) {
                    const genre = selectedArea; // Access the genre key using datum() for the current path
                    const value = closestData[genre]; // Get the value for the hovered genre
                    // Update and position the tooltip content
                    tooltipDiv.html(
                        `<strong>Genre: ${formatGenre(genre)}</strong><br/> Time: ${date_time}  <br/> Spins: ${value}`
                    )
                        .style("left", `${event.pageX + 10}px`)
                        .style("top", `${event.pageY + 50}px`);
                    // .style(`background`, ttColor);

                    tooltipDiv.style("opacity", 1);
                }

            }

            // for over the data
            const mousemove = function (event) {

                var mouseX = d3.pointer(event)[0];  // Get the mouse X position
                const closestData = getClosestDataPoint(mouseX); // Find the closest data point
                const hour = closestData.hour;
                const date_time = closestData.date + " " + formatHour(hour);   // Combine date and hour for tooltip
                const genre = d3.select(this).datum().key; // Access the genre key using datum() for the current path
                const value = closestData[genre]; // Get the value for the hovered genre
                // const ttColor = color(genre);


                // only if we do not have a data selection
                if (!selectedArea) {
                    tooltipDiv.style("opacity", 1);
                    d3.selectAll(".myArea").style("opacity", 0.2);
                    d3.select(this)
                        .style("stroke", "black")
                        .style("stroke-width", "0.05px")  // Set stroke width to thinner value
                        .style("opacity", 1);
                    // Update and position the tooltip content
                    tooltipDiv.html(
                        `<strong>Genre: ${formatGenre(genre)}</strong><br/> Time: ${date_time}  <br/> Spins: ${value}`
                    )
                        .style("left", `${event.pageX + 10}px`)
                        .style("top", `${event.pageY + 50}px`);
                    // .style(`background`, ttColor);

                    tooltipDiv.style("opacity", 1);
                }
            };

            // for mouse leaving whole svg area
            const mouseleave = function () {
                if (!selectedArea) {
                    tooltipDiv.style("opacity", 0);
                    verticalLine.style("opacity", 0);
                    d3.selectAll(".myArea")
                        .style("opacity", 1)
                        .style("stroke", "none")
                        .style("stroke-width", null);  // Reset stroke width
                }

            };

            // for clicking where there is no data
            const clickNoData = function () {
                tooltipDiv.style("opacity", 0);
                verticalLine.style("opacity", 0);
                d3.selectAll(".myArea")
                    .style("opacity", 1)
                    .style("stroke", "none")
                    .style("stroke-width", null);  // Reset stroke width
                selectedArea = null;
                cachedTarget = null;
            };

            // whole svg area (applies to everything)
            svg
                .attr("width", width)
                .attr("height", height)
                .on("mousemove", mousemoveAll)
                .on("mouseleave", mouseleave);


            // mouse tracking area
            svg
                .append("rect")
                .attr("width", width)
                .attr("height", height)
                .style("fill", "none")
                .style("pointer-events", "all")
                .on("click", clickNoData);


            // Area generator with curve interpolation (curveBasis for smooth lines)
            const area = d3
                .area()
                .curve(d3.curveBasisOpen)
                .x(function (d) {
                    return isNaN(x(d.data.datetime)) ? 0 : x(d.data.datetime); // Validate x value
                })
                .y0(function (d) {
                    return isNaN(y(d[0])) ? 0 : y(d[0]); // Validate y0 value
                })
                .y1(function (d) {
                    return isNaN(y(d[1])) ? 0 : y(d[1]); // Validate y1 value
                });


            // data areas
            const areas = svg
                .selectAll("mylayers")
                .data(stackedData)
                .enter()
                .append("path")
                .attr("class", "myArea")
                .attr("d", area)
                .style("fill", (i) => color(i))
                .on("mousemove", mousemove)
                .on("mouseleave", mouseleave)
                .on('click', clickData);


            // Function to handle zoom
            function zoomed(event) {


                const transform = event.transform;
                areas.attr("transform", transform); // Apply zoom to areas
                xAxisGroup.call(d3.axisBottom(x).scale(transform.rescaleX(x)).ticks(isMobile ? 4 : 6)); // Rescale the x-axis

                xAxisGroup.selectAll(".domain").style("stroke", "white");
                xAxisGroup.selectAll(".tick line").style("stroke", "white");
                xAxisGroup.selectAll(".tick text").style("fill", "white");
            }

            // Apply zoom behavior to the svg element
            const zoom = d3.zoom()
                .scaleExtent([0.5, isMobile ? 50 : 20]) // Limit zoom scale
                .translateExtent([[0, 0], [width + 30, height]]) // Limit panning area
                .on("zoom", zoomed);

            d3.select("svg").call(zoom);

            // Apply an initial zoom level of 1.5 (adjust this value as needed)
            const initialZoomLevel = isMobile ? 7 : 7;
            // Calculate the position to show the most recent date
            const mostRecentDate = d3.max(sfyData, d => d.datetime);
            const translateX = x(mostRecentDate) + (width * (isMobile ? 5 : 5))
            const translateY = isMobile ? 1300 : 1300;
            // Set the initial zoom transformation
            d3.select("svg").call(zoom.transform, d3.zoomIdentity.translate(-translateX, -translateY).scale(initialZoomLevel));
        }
    }, [loading, sfyData, error]);

    return (
        <div className="genre-stream" id="genre-stream">
            <h2>Genre Spins Over Time</h2>
            <p>This chart shows how the genre of your spins (listens) changes over time.
                The thickness of each color corresponds to how many songs were played in that hour
                with that genre. Click on a color to lock onto that genre, scroll to zoom, click and drag to pan.</p>
            <div id="my_dataviz" ref={svgRef}></div>
        </div>
    );
};

export default StreamGraphGenre;
