import React, { useEffect, useState, useRef } from "react";
import sfyApi from '../api/sfyApi';
import './GetMyTopGenres.css';
import { getCookie } from "../utils/cookies";
import { formatGenre } from "../utils/stringUtils";

// SPOTIFY get playlists
export const GetMyTopGenres = () => {
    const [sfyData, updateSfyData] = useState([]);
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state
    const hasFetched = useRef(false); // UseRef to track if the request has already been made

    useEffect(() => {
        const getPlaylists = async () => {
            const cookieString = getCookie("sessionUser");
            const user = JSON.parse(cookieString);

            const cachedData = localStorage.getItem("listTopGenres");

            if (cachedData) {
                updateSfyData(JSON.parse(cachedData));
                return;
            }

            try {
                setLoading(true); // Ensure loading state is set
                const data = await sfyApi.myTopGenres(user.email, "2024-06-01T00:00:00Z", new Date()); // Fetch playlists from the backend
                localStorage.setItem("listTopGenres", JSON.stringify(data)); // Cache the result in local storage
                updateSfyData(data);
                setError(null);
            } catch (err) {
                console.error("Error fetching genres:", err);
                setError("Server Error.");
            } finally {
                setLoading(false); // Set loading to false once request is complete
            }
        };

        if (!hasFetched.current) {
            getPlaylists();
            hasFetched.current = true; // Mark as fetched to prevent duplicate calls
        }
    }, []);

    const buildPlaylistContainer = () => {

        if (error) {
            return <p>{error}</p>;
        }

        if ((!sfyData || sfyData.length === 0) && !loading) {
            return <p>No Genres found</p>;
        }

        return (
            <div className="genres-container">

                <h2>My Top Genres:</h2>
                <ol>
                    {sfyData.map((item, index) => (
                        <li key={index} className="list-item">
                            {/* <img
                                src={item.image_url}
                                alt={formatGenre(item.genre_name)}
                                className="genre-image"
                            /> */}
                            <h1> #{index + 1}:&nbsp;</h1>

                            <div className="genre-info">
                                <p className="genre-name">{formatGenre(item.genre_name)}</p>
                                <p className="genre-spins">Spins: {item.c}</p>
                            </div>

                        </li>
                    ))}
                </ol>
            </div>
        );
    };

    return buildPlaylistContainer();
};

export default GetMyTopGenres;